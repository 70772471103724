import React from 'react'
import { Link } from 'gatsby'
import { siteMetadata } from '../../gatsby-config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDragon } from '@fortawesome/free-solid-svg-icons'


const Navlist = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  render() {
    

    return (
          <>
              {siteMetadata.menuLinks.map(link => (
                <Link className="navbar-item"  
                        style={{ 
                            color: `red`,
                            textDecoration: `underline`,
                        }} 
                        to={link.link} 
                        /* activeStyle={{ 
                            color: "orange",
                        }} */
                        key={link.name}>
                    <li
                        className='nav-selection'
                        key={link.name}
                        
                >
                 {link.name}
                </li>
                </Link>
            ))}
      </>
    )
  }
}

export default Navlist
