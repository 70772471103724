import React from 'react'
import Navlist from './Navlist'

import paypal from '../img/social/cc-paypal-brands.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-danger-light has-text-white-ter">

        <div className="content has-text-centered has-background-danger-light has-text-white-ter">
          <div className="container has-background-danger-light has-text-white-ter">
            <div style={{ maxWidth: '100vw' }} className="columns">
              <div className="column">
                <section className="menu">
                  <ul className="navbar">
                  <Navlist />
                  </ul>
                </section>
              </div>
              <div className="column is-offset-6 social">
                <a title="paypal" href="https://paypal.me/jawseef?country.x=US&locale.x=en_US">
                  <img
                    src={paypal}
                    alt="paypal"
                    style={{ width: '2em', height: '2em' }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
