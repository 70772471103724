import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import './all.sass'

const TemplateWrapper = ({ children, title, image }) => {
  //const { title, description } = useSiteMetadata()
  return (
    <div className={title === "gallery" ? "has-background-white has-text-black" : "has-background-black has-text-white"}>
      <Header title={title} image={image} />
      <div>{children}</div>
      <Footer />
    </div>
  )
}

export default TemplateWrapper
