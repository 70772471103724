import React from 'react'
import './all.sass'
import Navlist from './Navlist'

const Header = ({ title, image }) => {
  return (
    <div
      className="full-width-image-container margin-top-0"
        style={{
         backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`
        }}  
    >
      <h2
        className="has-text-weight-bold"
        style={{
          padding: '1rem',
        }}
      >
        {title}
      </h2>
      
      <section className="menu">
        <ul className="navbar">
        <Navlist />
        </ul>
      </section>
      
    </div>
  )
}

export default Header
