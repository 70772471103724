import React from 'react'
import Layout from '../../components/Layout'

const Contact = () => {
  return (
  <Layout image="/img/transparent.png" title="Thank You!">
    <section className="section">
      <div className="container">
        <div className="content">
          <p>Thank you for your feedback.</p>
        </div>
      </div>
    </section>
  </Layout>)
}
export default Contact;
